.ExplainSectionContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: left;
	padding-bottom: 30px;
	background-color: #f8f8f8;
}
.ExplainSectionWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.ExplainSectionImageSide {
	display: flex;
}
.ExplainSectionImageStyle {
	width: 100%;
}
.CatagoriasButtonContainer {
	font-weight: bold;
}
