.ExplainSectionBoxsContainer{
display:flex;
flex-direction:row;
background-color:#003caa;
color: white;
padding:10px;
padding-top: 15px;
padding-bottom: 15px;
margin-top:20px

}
.ExplainSectionBoxsTextWrapper{
    align-items:center;
    text-align:left;
    display:flex;
    padding-left: 10px;
}
.ExplainSectionBoxsImageWrapper{
    align-items:center;
    align-self:center;
    display:flex;
    
}