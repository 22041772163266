.NavBarContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	text-decoration: none;
	height: 50px;
}
.NavBarTextLinks {
	color: black;
	cursor: pointer;
	text-decoration: none;
	font-weight: bold;
	font-size: 1.2rem;
}

.NavBarTextLinksNotVisable {
	color: white;
	visibility: hidden;
	font-size: 1.2rem;
}
