.QASectionHomeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
}
.QASectionHomeWrapper {
	display: flex;
	flex-direction: column;
}
