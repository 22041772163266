.RecommendationItemContainer {
	border: 2px solid #dfdfdf;
	background-color: #f8f8f8;
	position: relative;
	margin-bottom: 20px;
	/* display:flex;
  align-items:center; */
}
.RecommendationItemWrapper {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.RecommendationItemNumberRank {
	position: absolute;
	background-color: #dfdfdf;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	padding: 5px;
	left: -22px;
	font-weight: bold;
	color: white;
	font-size: 2rem;
}
.goldBorder {
	border: 2px solid #ffb514;
}
.RecommendationItemRecommended {
	position: absolute;
	top: 0;
	background-color: #ffb514;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0px;
	font-weight: bold;
	color: white;
}
.RecommendationItemRate {
	background-color: #ffb514;
	font-weight: bold;
	padding: 4px;
	position: absolute;
	top: -2px;

	border-radius: 0px 0px 7px 7px;
}
.RecommendationItemLeftSideWrapper {
	display: flex;
	align-items: center;
}
.RecommendationItemLeftSideWrapperStarsAndCount {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.RecommendationItemRateText {
	font-weight: bold;
	padding: 4px;
	position: absolute;
	top: 0;
}
.RecommendationItemButtonLink {
	background-color: #14bf85;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 7px;
	padding-right: 7px;
	color: white;
	font-size: 1.2rem;
	font-weight: bold;
	border-radius: 8px;
	cursor: pointer;
}

.RecommendationItemButtonLinkGlow {
	box-shadow: 0px 0px 10px 1px #86d77c;
}
