.FooterContainer {
	background-color: #003caa;
	padding-bottom: 10px;
}
.FooterWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 20px;
}
.FooterImageWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.FooterText {
	color: white;
	opacity: 0.7;
}
