.RecommendationTextExplainWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-weight: bold;
	/* width: 100%; */
}
.RecommendationTextExplainSingleItemWrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 10px;
}
.RecommendationTextExplainImage {
	width: 15px;
	height: 14px;
	margin-right: 5px;
}
