.QASectionPrestamosContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
}
.QASectionPrestamosWrapper {
	display: flex;
	flex-direction: column;
	/* width:50%; */
}
