.CatagoriasButtonContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #f8f8f8;
	border: 1px solid #b4b4b4;
	width: 40%;
	min-width: 100px;
	height: 120px;
	margin: 10px;
	cursor: pointer;
}
.CatagoriasButtonImage {
	width: 50px;
}
