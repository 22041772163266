.HomeHeadSectionContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.HomeHeadSectionBGImage {
	background-image: url(../../../images/BlueBGHeadHome.png);
	width: 100%;
	background-size: cover;
}
.HomeHeadSectionWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.HomeHeadSectionText {
	text-align: left;
	font-weight: bold;
	color: white;
}
.HomeHeadSectionImg {
	margin-top: 30px;
	max-width: 340px;
}
