.CatagoriasContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
.CatagoriasWrapper {
	display: flex;
	flex-direction: column;
}
.CatagoriasButtons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}
.CatagoriasImageAndTextParagraphContainer {
	display: flex;
}

.CatagoriasImageAndTextParagraphHalfTextContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.CatagoriasImageStyle {
	width: 100%;
}
