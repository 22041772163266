@media only screen and (min-width: 801px) {
	/* HomeHeadSection */
	.HomeHeadSectionImg {
		width: 50%;
	}
	/* Catagorias */
	.CatagoriasImageAndTextParagraphContainer {
		flex-direction: row;
	}
	.CatagoriasWrapper {
		text-align: left;
	}
	.CatagoriasImageAndTextParagraphHalfContainer {
		width: 50%;
	}
	.CatagoriasImageAndTextParagraphHalfTextContainer {
		width: 50%;
	}

	/* ExplainSection */
	.ExplainSectionTextSide {
		width: 50%;
	}
	.ExplainSectionWrapper {
		flex-direction: row;
	}
	.ExplainSectionImageSide {
		width: 50%;
		align-self: flex-end;
		padding-left: 20px;
	}
	/* HeadTopPrestamos */
	.HeadTopPrestamosContainer {
		justify-content: space-around;
	}

	/* PrestamosHeadSection */
	.PrestamosHeadSectionImg {
		width: 50%;
	}
	/* RecommendationItem */
	.RecommendationItemNumberRank {
		top: 40%;
	}
	.RecommendationItemWrapper {
		flex-direction: row;
		height: 200px;
	}
	.RecommendationItemLeftSideWrapper {
		flex-direction: column;
		justify-content: center;
	}
	.RecommendationItemLogoImage {
		width: 220px;
		margin-bottom: 15px;
	}
	.RecommendationItemRate {
		font-size: 2rem;
		right: 30px;
	}
	.RecommendationItemRateText {
		font-size: 1.3rem;
		right: 100px;
	}
	.RecommendationItemRecommended {
		font-size: 1.3rem;
		padding: 5px;
	}
	.RecommendationTextExplainSection {
		width: 40%;
	}
	.RecommendationTextExplainSingleItemWrapper {
		font-weight: bold;
	}

	/* stars */
	.StarsImage {
		width: 26px;
	}
}

@media only screen and (max-width: 800px) {
	.NavBarTextLinks {
		visibility: hidden;
	}
	/* Home */
	.HomeHeadSectionContainer {
		position: relative;
	}
	.HomeHeadSectionWrapper {
		width: 90%;
		margin-bottom: 110px;
	}
	.HomeHeadSectionImg {
		position: absolute;
		bottom: 0;
		right: 20px;
		width: 200px;
	}
	/* Catagorias */
	.CatagoriasTitle {
		display: none;
	}
	.CatagoriasButtons {
	}
	.CatagoriasWrapper {
		width: 90%;
		text-align: center;
	}
	.CatagoriasImageAndTextParagraphContainer {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
	.CatagoriasImageAndTextParagraphHalfContainer {
		width: 80%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.CatagoriasImageAndTextParagraphHalfTextContainer {
		text-align: left;
		width: 80%;
		padding-top: 18px;
		height: 240px;
	}

	/* ExplainSection */
	.ExplainSectionTextSide {
		width: 100%;
	}
	.ExplainSectionWrapper {
		width: 90%;
		flex-direction: column;
	}
	.ExplainSectionImageSide {
		width: 90%;
		align-items: center;
	}
	.ExplainSectionImageStyle {
		margin-top: 20px;
	}

	/* QASectionHome */
	.QASectionHomeWrapper {
		width: 90%;
	}

	/* HeadTopPrestamos */
	.HeadTopPrestamosContainer {
		justify-content: center;
	}

	/* PrestamosHeadSection */
	.PrestamosHeadSectionContainer {
		position: relative;
	}
	.PrestamosHeadSectionWrapper {
		width: 90%;
		margin-bottom: 110px;
	}
	.PrestamosHeadSectionImg {
		position: absolute;
		bottom: 0;
		right: 20px;
		width: 200px;
	}

	/* Recommendation */

	.RecommendationsWrapper {
		width: 90%;
	}

	.TitleWrapper {
		width: 90%;
		font-size: 10px;
	}

	/* RecommendationItem */
	.RecommendationItemNumberRank {
		top: -2px;
		z-index: 3;
	}
	.RecommendationItemWrapper {
		flex-direction: column;
	}
	.RecommendationItemLeftSideWrapper {
		justify-content: space-between;
		flex-direction: row;
		margin-top: 40px;
		width: 100%;
		font-size: 12px;
		width: 90%;
	}
	.RecommendationItemLogoImage {
		width: 160px;
		margin-bottom: 15px;
	}
	.RecommendationItemRate {
		font-size: 1.2rem;
		right: 10px;
	}
	.RecommendationItemRateText {
		font-size: 0.9rem;
		right: 50px;
	}
	.RecommendationItemRecommended {
		font-size: 0.7rem;
		padding: 5px;
		padding-left: 25px;
		z-index: 2;
	}
	.RecommendationTextExplainSection {
		margin-top: 10px;
		margin-bottom: 10px;
		width: 94%;
		padding-top: 16px;
		border-top: 2px solid #dfdfdf;
	}
	.RecommendationTextExplainSingleItemWrapper {
		font-size: 14px;
		margin-bottom: 5px;
		font-weight: normal;
	}
	.RecommendationItemButtonLink {
		margin-bottom: 20px;
	}
	/* stars */
	.StarsImage {
		width: 20px;
	}
	/* QASectionPrestamos */
	.QASectionPrestamosWrapper {
		width: 90%;
	}
}

@media only screen and (max-width: 400px) {
	.NavBarTextLinks {
		display: none;
	}

	.RecommendationItemLogoImage {
		width: 120px;
		margin-bottom: 15px;
	}
	/* stars */
	.StarsImage {
		width: 15px;
	}
}
