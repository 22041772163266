.SingelQAContainer{
    width: 100%;
    text-align:left;
    border-bottom: 1px solid #ccc;
    margin-bottom:20px;
    display: flex;
    flex-direction: column;
}
.QuestionWrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content:space-between;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 20px;
}
.QuestionText{
  display: flex;
  align-items:center;
}
.AnswerWrapper{
    width: 100%;
    margin-bottom: 20px;
}
.chevron {
  display: inline-block;
  margin: 1rem;
  vertical-align: middle;
  width: 40px;
  cursor: pointer;
}
.chevron {
  width: 18px;
  height: 15px;

  &__group {
    transform: translateY(0);
    transition: transform 0.1s linear;
  }

  &__box--left,
  &__box--right {
    transform: rotate(0) translateY(0);
    transition: transform 0.1s linear;
  }

  &__box--left {
    transform-origin: 1px 1px;
  }

  &__box--right {
    transform-origin: 7px 1px;
  }

  &--flip &__box--left {
    transform: rotate(-90deg) translateY(0);
  }

  &--flip &__box--right {
    transform: rotate(90deg) translateY(0);
  }

  &--flip &__group {
    transform: translateY(3px);
  }
}
