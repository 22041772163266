.PrestamosHeadSectionContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.PrestamosHeadSectionBGImage {
	background-image: url(../../../images/BlueBGHeadHome.png);
	width: 100%;
	background-size: cover;
}
.PrestamosHeadSectionWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}
.PrestamosHeadSectionText {
	text-align: left;
	font-weight: bold;
	color: white;
}
.PrestamosHeadSectionImg {
	margin-top: 30px;
	max-width: 340px;
}
