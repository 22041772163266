@font-face {
	font-family: 'Quicksand';
	src: url('./fonts/Quicksand/Quicksand-VariableFont_wght.ttf'); /* IE9 Compat Modes */
	src: url('./fonts/Quicksand/Quicksand-VariableFont_wght.ttf')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/Quicksand/Quicksand-VariableFont_wght.ttf')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/Quicksand/Quicksand-VariableFont_wght.ttf') format('woff'),
		/* Pretty Modern Browsers */
			url('./fonts/Quicksand/Quicksand-VariableFont_wght.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/Quicksand/Quicksand-VariableFont_wght.ttf') format('svg'); /* Legacy iOS */
}
.App {
	text-align: center;
	font-family: 'Quicksand';
}
